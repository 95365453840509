import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useCurrentUsersCompaniesQuery } from '@/api/useCompany'

export function useCompany() {
  const route = useRoute()

  const { data: companiesList } = useCurrentUsersCompaniesQuery({
    includeCurrentCompany: true,
    includeDescendantCompanies: true,
  })

  const companyId = computed(() => route.params?.companyId)

  const companyName = computed(() => {
    if (!companiesList.value || !companyId.value) {
      return ''
    }
    const company = companiesList.value.find(
      (company) => company.id.toString() === companyId.value
    )

    return company ? company.name : companiesList.value.at(0)?.name || ''
  })

  const companyInitials = computed(() => {
    if (!companyName.value || typeof companyName.value !== 'string') {
      return ''
    }

    const parts = companyName.value.trim().split(/\s+/)
    if (parts.length === 0) {
      return ''
    }

    const initials = parts
      .slice(0, 2)
      .map((part) => part[0])
      .join('')

    return initials.toUpperCase()
  })

  return {
    companyId,
    companiesList,
    companyName,
    companyInitials,
  }
}
